<template>
    <div class="page-operatorsmanage">
        <b-card class="cardtop cardnewoperator rounded m-0">
            <b-avatar class="bg-body-secondary text-secondary border border-5 border-white" size="7em"></b-avatar>
            <h5 class="cardtoptitle">Novo Operador(a)</h5>
            <b-button class="text-green bg-white border-0" v-b-modal.modalEditOperator @click="verifyOperatorsQuantity">Criar</b-button>
        </b-card>
        <!-- <b-card class="cardtop cardanswers">
            <b-icon class="cardanswersicon" icon="chat-text"></b-icon>
            <h5 class="cardtoptitle">Minhas Respostas</h5>
            <b-button v-b-modal.modal-operatorsnewanswer class="cardtopbtn">Ver Respostas</b-button>
        </b-card> -->
        <b-card class="operatorscard">
            <div class="operatorscardheader">
                <h5 class="operatorscardtitle text-purple">Operadores</h5>
                <div class="operatorscardheaderright">
                    <span class="operatorscardtexttotal text-purple">Total de Operadores</span>
                    <span class="operatorscardnumbertotal rounded-pill">{{operators.length}}</span>
                    <b-button class="operatorscardheaderbtn" v-b-modal.modal-operatorsfilter>Filtrar</b-button>
                </div>
            </div>
            <div class="operatorscardsubheader">
                <b-row>
                    <b-col class="operatorscardcol">
                        <div class="operatorscardshtitle text-purple">Operador(a)</div> 
                    </b-col>
                    <b-col class="operatorscardcol">
                        <div class="operatorscardshtitle text-purple">Departamentos</div>
                    </b-col>
                    <b-col class="operatorscardcol">
                        <div class="operatorscardshtitle text-purple">Status</div>
                    </b-col>
                    <b-col class="operatorscardcol">
                        <div class="operatorscardshtitle text-purple">Deslogar</div>
                    </b-col>
                    <!-- <b-col class="operatorscardcol">
                        <div class="operatorscardshtitle">Ativo</div>
                        <div class="operatorscardshboxes">
                            <label><input type="checkbox" name="checkbox-01" /><span></span></label>
                            <b-form-select class="operatorscardshselect" :options="selectoperatoroptions"></b-form-select>
                        </div>
                    </b-col> -->
                </b-row>
            </div>
            <div class="operatorscardbody" v-if="operators.length">
                <b-row class="operatorscardbodyrow" v-for="operator in operators" :key="operator._id">
                    <b-col class="operatorscardcol">
                        <div class="fw-semibold text-purple hstack gap-2">
                            <b-avatar class="bg-secondary text-white" size="3em" :src="operator.photoURL"></b-avatar>
                            {{ operator.name }}
                        </div> 
                    </b-col>
                    <b-col class="operatorscardcol">
                        <div class="operatorscarddescription text-purple" v-if="operator.department?.length">
                            <span v-for="(dep,i) in operator.department" :key="dep?._id">
                                <span>
                                    {{ depName(dep) }}<span v-if="i+1<operator.department.length">,</span> 
                                </span>
                            </span>
                        </div>
                    </b-col>
                    <b-col class="operatorscardcol w-100">
                        <div class="d-flex align-items-center">
                            <div class="operatorscarddescription hstack gap-1 small-text">
                                <span class="rounded-circle d-inline-block" :class="operator.status ? 'bg-green' : 'bg-danger'" style="width: 1em; height: 1em;"></span>
                                <span class="text-green" v-if="operator.status">Online</span>
                                <span class="text-danger" v-else>Offline</span>
                            </div>
                        </div>
                        <!-- <div class="operatorscardoptionsbtns">
                            <b-button class="operatorscardoptionsbtn" @click.prevent="selectUser(operator)" v-b-modal.modalEditOperator v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" style="background-color:hsl(94deg 44% 65%)"><b-icon icon="pencil-square"></b-icon></b-button>
                            <b-button class="operatorscardoptionsbtn" @click.prevent="selectUser(operator)" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" v-b-modal.modal-deleteOperator style="background-color:hsl(360deg 83% 69%)"><b-icon icon="trash"></b-icon></b-button>
                        </div> -->
                    </b-col>
                    <b-col class="operatorscardcol w-100">
                        <div class="hstack">
                            <div class="operatorscarddescription hstack gap-1 small-text">
                                <span class="form-check form-switch m-0 p-0 min-h-0">
                                    <b-form-checkbox v-model="operator.connected" :disabled="!operator.connected" @change="operatorLogout(operator)"></b-form-checkbox>
                                </span>
                                <span class="text-green" v-if="operator.connected">Logado</span>
                                <span class="text-danger lh-1" v-else>Deslogado</span>
                            </div>
                        </div>
                        <div class="hstack gap-2">
                            <b-button class="operatorscardoptionsbtn shadow p-1 bg-purple" @click.prevent="selectUser(operator)" v-b-modal.transferContacts v-b-tooltip.hover.bottom="{ customClass: 'tooltip-purple top-0', boundary: 'document' }" title="Transferir carteira" v-if="user.channelConfig?.enableCustomerPortfolio"><b-icon icon="forward"></b-icon></b-button>
                            <b-button class="operatorscardoptionsbtn shadow p-1" @click.prevent="selectUser(operator)" v-b-modal.modalEditOperator v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" style="background-color:hsl(94deg 44% 65%)"><b-icon icon="pencil-square"></b-icon></b-button>
                            <b-button class="operatorscardoptionsbtn shadow p-1" @click.prevent="selectUser(operator)" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" v-b-modal.modal-deleteOperator style="background-color:hsl(360deg 83% 69%)"><b-icon icon="trash"></b-icon></b-button>
                        </div>
                    </b-col>
                    <!-- <b-col class="operatorscardcol">
                        <div class="operatorscardcontacts">{{operator.active}}</div>
                    </b-col> -->
                </b-row>
            </div>
            <div class="text-center p-3" v-else>
                Nenhum Operador encontrado!
            </div>
            <!-- <div class="d-flex align-items-center justify-content-between p-3" v-if="operators.length>10">
                <b-pagination class="m-0"
                ></b-pagination>
                <div class=".text-secondary">1 - 10 de 100 operadores cadastrados</div>
            </div> -->
        </b-card>
        <b-modal id="modalEditOperator" ref="modalEditOperator" :title="arrayAux._id ? 'Editar Operador' : 'Criar Operador'" size="xl" v-if="arrayAux">
            <b-form @submit.prevent="saveUser">
                <b-row class="m-0">
                    <b-col class="p-0 me-2">
                        <b-form-group label="Nome Completo:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.name" class="modal-operatorsnewanswerinput" required/>
                        </b-form-group>
                        <b-form-group label="Nickname:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.nickname" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                        <b-form-group label="Usuário:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.login" class="modal-operatorsnewanswerinput" autocomplete="nope" :required="!arrayAux._id"/>
                        </b-form-group>
                        <b-form-group label="Senha:" label-class="small-text fw-normal">
                            <b-form-input type="password" v-model="arrayAux.password" class="modal-operatorsnewanswerinput" autocomplete="nope" :required="!arrayAux._id"/>
                        </b-form-group>
                        <b-form-group label="Email:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.email" class="modal-operatorsnewanswerinput" :required="crmEnabled"/>
                        </b-form-group>
                        <b-form-group label="Celular:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.phone" placeholder="55dd88888888" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                        <b-row class="m-0">
                            <b-col class="p-0 me-3">
                                <b-form-group label="Gênero:" label-class="small-text fw-normal">
                                    <b-form-select v-model="arrayAux.genre" class="modal-operatorsnewanswerselect rounded">
                                        <b-form-select-option value="Masculino">Masculino</b-form-select-option>
                                        <b-form-select-option value="Feminino">Feminino</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0">
                                <b-form-group label="Data de Nascimento:" label-class="small-text fw-normal">
                                    <b-form-input type="date" v-model="arrayAux.birthday" class="modal-operatorsnewanswerinput"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-form-group label="Departamentos:" label-class="small-text fw-normal">
                            <div class="position-relative">
                                <div class="border rounded w-100 text-secondary py-1 px-3 d-flex justify-content-between" style="border-color: #eee !important" role="button" v-b-toggle.collapseDepSelect>
                                    <span v-if="arrayAux.department && arrayAux.department.length">
                                        <span v-for="(dep,i) in arrayAux.department" :key="dep.id">
                                            <span>
                                                {{ depName(dep) }}<span v-if="i+1 < arrayAux.department.length">,</span> 
                                            </span>
                                        </span>
                                    </span>
                                    <span v-else>
                                        &nbsp;
                                    </span>
                                    <span>
                                        <b-icon class="text-dark" icon="chevron-down" style="margin-right: -0.9em" font-scale="0.75"></b-icon>
                                    </span>
                                </div>
                                <b-collapse id="collapseDepSelect" ref="collapseDepSelect" class="mt-2 position-absolute bg-white border w-100 rounded" style="z-index: 1">
                                    <b-form-checkbox-group
                                        v-model="arrayAux.department"
                                        style="z-index: 1"
                                    >
                                        <div v-for="item in departments" :key="item.id">
                                            <b-form-checkbox class="text-secondary small-text d-flex align-items-center border p-3 w-100" role="button" :value="item._id"> 
                                                <div class="ms-1" role="button">{{ item.name }}</div>
                                            </b-form-checkbox>
                                        </div>
                                    </b-form-checkbox-group>
                                    <div class="w-100 h-100 position-fixed top-0 end-0 start-0 bottom-0" role="window" style="z-index: -1" v-b-toggle.collapseDepSelect>
                                    </div>
                                </b-collapse>
                            </div>
                        </b-form-group>
                        <div>
                            <b-form-group label="Dias de Acesso:" label-class="small-text fw-normal">
                                <b-form-checkbox-group
                                    v-model="arrayAux.weekDays"
                                    class="d-flex"
                                >
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="1"> <div class="ms-1">Seg</div> </b-form-checkbox>
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="2"> <div class="ms-1">Ter</div> </b-form-checkbox>
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="3"> <div class="ms-1">Qua</div> </b-form-checkbox>
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="4"> <div class="ms-1">Qui</div> </b-form-checkbox>
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="5"> <div class="ms-1">Sex</div> </b-form-checkbox>
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="6"> <div class="ms-1">Sab</div> </b-form-checkbox>
                                    <b-form-checkbox class="me-2 text-secondary small-text d-flex align-items-center" value="0"> <div class="ms-1">Dom</div> </b-form-checkbox>
                                </b-form-checkbox-group>
                            </b-form-group>
                        </div>
                        <div class="vstack gap-2" v-if="arrayAux.weekDays">
                            <div class="row d-flex" v-if="scheduleDays([1,2,3,4,5])">
                                <div class="col pe-2">
                                    <div class="bg-grey rounded d-flex align-items-center me-1 px-2 h-100 small-text text-secondary">Escala da Semana</div>
                                </div>
                                <div class="col d-flex align-items-center px-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">De</div>	
                                    <b-form-input type="time" v-model="arrayAux.weekStartTime" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                                <div class="col d-flex align-items-center ps-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">Até</div>	
                                    <b-form-input type="time" v-model="arrayAux.weekFinishTime" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                            </div>
                            <div class="row d-flex" v-if="scheduleDays([6])">
                                <div class="col pe-2">
                                    <div class="bg-grey rounded d-flex align-items-center me-1 px-2 h-100 small-text text-secondary">Sábado</div>
                                </div>
                                <div class="col d-flex align-items-center px-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">De</div>	
                                    <b-form-input type="time" v-model="arrayAux.satStartTime" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                                <div class="col d-flex align-items-center ps-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">Até</div>	
                                    <b-form-input type="time" v-model="arrayAux.satFinishTime" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                            </div>
                            <div class="row d-flex" v-if="scheduleDays([0])">
                                <div class="col pe-2">
                                    <div class="bg-grey rounded d-flex align-items-center me-1 px-2 h-100 small-text text-secondary">Domingo</div>
                                </div>
                                <div class="col d-flex align-items-center px-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">De</div>	
                                    <b-form-input type="time" v-model="arrayAux.sunStartTime" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                                <div class="col d-flex align-items-center ps-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">Até</div>	
                                    <b-form-input type="time" v-model="arrayAux.sunFinishTime" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                            </div>
                            <div class="row d-flex" v-for="(breakTime, index) in arrayAux.breakTime" :key="breakTime._id">
                                <div class="col pe-2 col-4">
                                    <div class="bg-grey rounded d-flex align-items-center me-1 px-2 h-100 small-text text-secondary">Horário de Intervalo</div>
                                </div>
                                <div class="col d-flex align-items-center px-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">De</div>	
                                    <b-form-input type="time" v-model="breakTime.start" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                                <div class="col d-flex align-items-center px-2">
                                    <div class="bg-grey rounded-start h-100 d-flex align-items-center p-2 small-text text-secondary">Até</div>	
                                    <b-form-input type="time" v-model="breakTime.finish" class="modal-operatorsnewanswerinput h-100" style="border-top-left-radius: 0; border-bottom-left-radius: 0;"/>
                                </div>
                                <div class="col d-flex align-items-center ps-2 col-1 w-fit">
                                    <b-button class="px-2 text-white" variant="red" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" @click="removeBreakTime(index)">
                                        <b-icon icon="trash" />
                                    </b-button>
                                </div>
                            </div>
                            <div class="row d-flex">
                                <div class="col pe-2 col-4">
                                    <div class="bg-grey rounded d-flex align-items-center me-1 px-2 h-100 small-text text-secondary">Horário de Intervalo</div>
                                </div>
                                <div class="col px-2">
                                    <b-button class="border-grey bg-white text-secondary" @click="addBreakTime">Adicionar</b-button>
                                </div>
                            </div>
                        </div>
                    </b-col>
                    <b-col class="p-0 ms-2">
                        <b-form-group label="Código Postal:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.cep" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                        <b-form-group label="Endereço:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.street" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                        <b-row class="m-0">
                            <b-col class="p-0 me-2">
                                <b-form-group label="Número:" label-class="small-text fw-normal">
                                    <b-form-input v-model="arrayAux.number" class="modal-operatorsnewanswerinput"/>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ms-2">
                                <b-form-group label="Bairro:" label-class="small-text fw-normal">
                                    <b-form-input v-model="arrayAux.neighborhood" class="modal-operatorsnewanswerinput"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="m-0">
                            <b-col class="p-0 me-2">
                                <b-form-group label="Cidade:" label-class="small-text fw-normal">
                                    <b-form-input v-model="arrayAux.city" class="modal-operatorsnewanswerinput"/>
                                </b-form-group>
                            </b-col>
                            <b-col class="p-0 ms-2">
                                <b-form-group label="Estado:" label-class="small-text fw-normal">
                                    <b-form-input v-model="arrayAux.state" class="modal-operatorsnewanswerinput"/>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <div class="d-flex flex-column">
                            <div class="fw-bold text-secondary">Configurações:</div>
                            <div>
                                <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" v-model="arrayAux.disableAudio"> <div class="ms-1">Desabilitar recurso de envio de áudio</div> </b-form-checkbox>
                                <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" v-model="arrayAux.disableMedia"> <div class="ms-1">Desabilitar recurso de envio de mídia (imagens, vídeos ou arquivos)</div> </b-form-checkbox>
                                <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" v-model="arrayAux.disableEditName"> <div class="ms-1">Desabilitar recurso de edição do nome do contato</div> </b-form-checkbox>
                                <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" v-model="arrayAux.disableActiveCommunication"> <div class="ms-1">Desabilitar recurso de comunicação ativa</div> </b-form-checkbox>
                                <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" :disabled="arrayAux.attendanceViewRestriction || arrayAux.restrictOperatorOwnMessages" v-model="arrayAux.viewOperatorContactsOnly" v-if="user.channelConfig?.enableCustomerPortfolio">
                                    <div class="ms-1">Habilitar restrição de busca a clientes carteirizados</div>
                                </b-form-checkbox>
                                <!-- <div class="d-flex flex-column gap-2">
                                    <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" :disabled="arrayAux.viewOperatorContactsOnly || arrayAux.restrictOperatorOwnMessages" v-model="arrayAux.attendanceViewRestriction">
                                        <div class="ms-1">Habilitar restrição de histórico de atendimentos</div>
                                    </b-form-checkbox>
                                    <div v-if="arrayAux.attendanceViewRestriction">
                                        <b-form-select class="modal-operatorsnewanswerselect rounded mb-1 ms-3" v-model="arrayAux.attendanceViewRestrictionType">
                                            <b-form-select-option :value="null || undefined" disabled>Selecione a restrição</b-form-select-option>
                                            <b-form-select-option value="1">Por Operador</b-form-select-option>
                                            <b-form-select-option value="2">Por Equipe</b-form-select-option>
                                        </b-form-select>
                                    </div>
                                </div> -->
                                <div class="d-flex flex-column gap-2">
                                    <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" :checked="arrayAux.attendanceViewRestriction || arrayAux.restrictOperatorOwnMessages" :disabled="arrayAux.viewOperatorContactsOnly" @change="changedRestrictOperatorOwnMessages">
                                        <div class="ms-1">Habilitar restrição de histórico de atendimentos</div>
                                    </b-form-checkbox>
                                    <div class="w-100" v-if="arrayAux.restrictOperatorOwnMessages || arrayAux.attendanceViewRestriction">
                                        <b-form-select class="modal-operatorsnewanswerselect rounded ms-3" style="width: -webkit-fill-available" v-model="arrayAux.attendanceViewRestrictionType" v-if="arrayAux.attendanceViewRestriction">
                                            <b-form-select-option :value="null || undefined" disabled>Selecione a restrição</b-form-select-option>
                                            <b-form-select-option value="1">Por Operador</b-form-select-option>
                                            <b-form-select-option value="2">Por Equipe</b-form-select-option>
                                        </b-form-select>
                                        <b-form-select class="modal-operatorsnewanswerselect rounded ms-3" style="width: -webkit-fill-available" v-model="arrayAux.restrictOperatorOwnMessagesType" v-else>
                                            <b-form-select-option :value="null || undefined" disabled>Selecione a restrição</b-form-select-option>
                                            <b-form-select-option value="1">Por Operador</b-form-select-option>
                                            <b-form-select-option value="2">Por Equipe</b-form-select-option>
                                        </b-form-select>
                                        <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text ms-3 mt-2" :disabled="arrayAux.attendanceViewRestriction" v-model="arrayAux.restrictOperatorOwnMessagesHideChatbot">
                                            <div class="ms-1">Restringir visualização de comunicação com chatbot</div>
                                        </b-form-checkbox>
                                        <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text ms-3 mt-2" :disabled="arrayAux.restrictOperatorOwnMessagesHideChatbot" v-model="arrayAux.attendanceViewRestriction" @change="changedAttendanceViewRestriction">
                                            <div class="ms-1">Encaminhar histórico do protocolo</div>
                                        </b-form-checkbox>
                                    </div>
                                </div>
                                <!-- <b-form-checkbox class="d-flex align-items-center text-secondary smaller-text" v-model="arrayAux.disableEditName"> <div class="ms-1">Habilitar visualização de histórico de atendimentos efetuados pelo operador</div> </b-form-checkbox> -->
                            </div>
                        </div>
                        <b-form-group class="mt-2" label="Quantidade de atendimentos máximo em fila de espera:" label-class="small-text fw-normal">
                            <b-form-input type="number" v-model="arrayAux.numberQueueAttendance" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                        <div v-if="user.channelConfig?.enableCustomerPortfolio && arrayAux.portfolioSecondaryAttendance">
                            <b-form-group label-class="small-text fw-normal" label="Atendimento secundário para carteira de clientes:">
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="arrayAux.portfolioSecondaryAttendance.action">
                                    <b-form-select-option :value="null">Nenhum</b-form-select-option>
                                    <b-form-select-option value="redirectDepartment">Departamento</b-form-select-option>
                                    <b-form-select-option value="redirectOperator">Operador</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                            <b-form-group class="mt-2" label-class="small-text fw-normal" :label="`Selecione o ${ arrayAux.portfolioSecondaryAttendance.action == 'redirectDepartment' ? 'Departamento' : 'Operador' }:`" v-if="arrayAux.portfolioSecondaryAttendance.action">
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="arrayAux.portfolioSecondaryAttendance.id" v-if="arrayAux.portfolioSecondaryAttendance.action == 'redirectDepartment'">
                                    <b-form-select-option :value="dep._id" v-for="dep in departments" :key="dep._id">{{ dep.name }}</b-form-select-option>
                                </b-form-select>
                                <b-form-select class="text-secondary w-100 rounded p-2 border-grey" v-model="arrayAux.portfolioSecondaryAttendance.id" v-else-if="arrayAux.portfolioSecondaryAttendance.action == 'redirectOperator'">
                                    <b-form-select-option :value="op._id" v-for="op in operators" :key="op._id">{{ op.name }}</b-form-select-option>
                                </b-form-select>
                            </b-form-group>
                        </div>
                        <b-form-group class="mt-2" label="Token CRM:" label-class="small-text fw-normal" v-if="user.channelConfig?.modules?.crm && arrayAux.department && ['all', ...arrayAux.department].some(e => user.channelConfig?.rdDepartments?.includes(e))">
                            <b-form-input v-model="arrayAux.rdInstanceToken" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                        <b-form-group class="mt-2" label="Código do Vendedor:" label-class="small-text fw-normal">
                            <b-form-input v-model="arrayAux.vendorId" class="modal-operatorsnewanswerinput"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <div class="mt-4">
                    <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                    <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modalEditOperator')">Cancelar</b-button>
                </div>
            </b-form>
        </b-modal>
        <b-modal id="modal-operatorsfilter" ref="modal-operatorsfilter" title="Filtrar Operadores">
            <b-form @submit.prevent="filterOperators">
                <b-form-group label="Selecione o filtro">
                    <b-form-select class="modal-select w-100 p-2 rounded" v-model="filters.type" :options="selectfilteroptions"></b-form-select>
                </b-form-group>
                <div v-if="filters.type">
                    <b-form-input v-model="filters.keyword" placeholder="Busca por Nome Operador" class="border-grey mb-3" v-if="filters.type == 'keyword'"/>
                    <b-form-group label="Selecione o status" v-else-if="filters.type == 'status'">
                        <b-form-select class="modal-select w-100 p-2 rounded" v-model="filters.status">
                            <b-form-select-option :value="true">
                                Online
                            </b-form-select-option>
                            <b-form-select-option :value="false">
                                Offline
                            </b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                    <b-form-group class="mt-3" label="Departamento" v-else-if="filters.type == 'department'">
                        <b-form-select class="border-grey w-100 p-2" v-model="filters.department">
                            <b-form-select-option v-for="item in departments" :key="item.id" :value="item._id">{{item.name}}</b-form-select-option>
                        </b-form-select>
                    </b-form-group>
                </div>
                <b-button type="submit" class="modal-btn modal-btnsearch">Pesquisar</b-button>
                <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modal-operatorsfilter')">Cancelar</b-button>
            </b-form>
        </b-modal>
        <b-modal id="modal-operatorsnewanswer" ref="modal-operatorsnewanswer" title="Nova Resposta" size="lg">
            <div style="border-bottom: .5px solid #eee">
                <b-row>
                    <b-col>
                        <b-form-group label="Categoria: ">
                            <b-form-select class="modal-operatorsnewanswerselect"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col>
                        <b-form-group label="Título: ">
                            <b-form-input class="modal-operatorsnewanswerinput" placeholder="Título"/>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-form-group label="Corpo da Mensagem">
                        <b-form-textarea class="modal-operatorsnewanswertextarea" rows="5" placeholder="Minha Resposta" no-resize></b-form-textarea>
                    </b-form-group>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group label="Filtros: ">
                            <b-form-select class="modal-operatorsnewanswerselect"></b-form-select>
                        </b-form-group>
                    </b-col>
                    <b-col cols="2" style="margin-top: 30px">
                        <b-button class="modal-btn modal-btnsearch">Pesquisar</b-button>
                    </b-col>
                </b-row>
            </div>
            <div class="operatorsnewanswersubheader">
                <b-row>
                    <b-col class="operatorscardcol" cols="8">
                        <div class="operatorsnewanswershtitle">Mensagem</div> 
                    </b-col>
                    <b-col class="operatorscardcol">
                        <div class="operatorsnewanswershtitle">Data de Criação</div>
                        <div class="operatorscardshboxes">
                            <label><input type="checkbox" name="checkbox-01" /><span></span></label>
                            <b-form-select class="operatorscardshselect" :options="selectnewansermsgoptions"></b-form-select>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div style="border-bottom: .5px solid #eee;" v-for="item in message" :key="item.id">
                <b-row class="newanswermsgrow" >
                    <b-col style="display:flex" cols="8">
                        <b-form-checkbox></b-form-checkbox>
                        <div class="newanswermsg">
                            <div class="newanswermsgtitle">{{item.title}}</div>
                            <div class="newanswermsgcontent">{{item.content}}</div>
                        </div>
                    </b-col>
                    <b-col class="newanswermsgcol">
                        <div class="newanswermsgdate">{{item.date}}</div>
                        <div>
                            <b-button class="operatorscardoptionsbtn" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-edit top-0', boundary: 'document' }" title="Editar" style="background-color:hsl(94deg 44% 65%)"><b-icon icon="pencil-square"></b-icon></b-button>
                            <b-button class="operatorscardoptionsbtn" v-b-tooltip.hover.bottom="{ customClass: 'tooltip-remove top-0', boundary: 'document' }" title="Remover" style="background-color:hsl(360deg 83% 69%)"><b-icon icon="trash"></b-icon></b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div class="operatorscardpagination">
                <b-pagination
                ></b-pagination>
                <div class=".text-secondary">1 - 10 de 100 operadores cadastrados</div>
            </div>
            <b-button class="modal-btn modal-btnsearch">Salvar</b-button>
            <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modal-operatorsnewanswer')">Cancelar</b-button>
        </b-modal>
        <b-modal id="modal-operatoredit" ref="modal-operatoredit" v-if="arrayAux" title="Editar Perfil" size="lg">
            <form @submit.prevent="editOperator">
                <b-tabs class="modal-operatoredittabtop" content-class="mt-3" fill>
                    <b-tab title="Perfil" active>
                        <ModalOperatorsHeader :user="arrayAux"/>
                        <div class="modal-operatoreditprofile">
                            <b-row>
                                <b-col>
                                    <b-form-group label="Nome Completo:">
                                        <b-form-input v-model="arrayAux.name" required/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Código Postal:">
                                        <b-form-input v-model="arrayAux.cep"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <b-form-group label="Senha:">
                                                <b-form-input type="password" v-model="arrayAux.password" required/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group label="Confirmar Senha:">
                                                <b-form-input type="password" v-model="arrayAux.password" required/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Endereço:">
                                        <b-form-input v-model="arrayAux.street"/>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Email:">
                                        <b-form-input v-model="arrayAux.email"/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <b-form-group label="Número:">
                                                <b-form-input v-model="arrayAux.streetnumber"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group label="Complemento:">
                                                <b-form-input v-model="arrayAux.complement"/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Celular:">
                                        <b-form-input v-model="arrayAux.mobile" required/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <b-form-group label="Bairro:">
                                                <b-form-input v-model="arrayAux.district"/>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group label="Cidade:">
                                                <b-form-input v-model="arrayAux.city"/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-row>
                                        <b-col>
                                            <b-form-group label="Gênero:">
                                                <b-form-select class="modal-operatoreditselectgender" :options="selectgenderoptions" v-model="arrayAux.gender"></b-form-select>
                                            </b-form-group>
                                        </b-col>
                                        <b-col>
                                            <b-form-group label="Data de Nascimento:">
                                                <b-form-input v-model="arrayAux.birthday"/>
                                            </b-form-group>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Estado:">
                                        <b-form-select v-model="arrayAux.state" :options="selectstateoptions"></b-form-select>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col>
                                    <b-form-group label="Estado Emissor: ">
                                        <b-form-tags class="inputtagsstate" placeholder="" input-id="tags-state" style="height: 100px !important"></b-form-tags>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Dados de Localização:">
                                        <Map />
                                    </b-form-group>
                                </b-col>
                            </b-row>
                        </div>
                        <b-button type="submit" class="modal-btn modal-btnsearch">Salvar</b-button>
                        <b-button class="modal-btn modal-btncancel" @click="$bvModal.hide('modal-operatoredit')">Cancelar</b-button>
                    </b-tab>
                    <b-tab title="Sócio Econômico">
                        <ModalOperatorsHeader :user="arrayAux"/>
                    </b-tab>
                    <b-tab title="Configurações">
                        <ModalOperatorsHeader :user="arrayAux"/>
                    </b-tab>
                    <b-tab title="Acessos">
                        <ModalOperatorsHeader :user="arrayAux"/>
                        <div>
                            <p class="modal-operatoredittitle">Histórico de Acessos</p>
                            <div class="modal-operatoreditaccesshistory" v-for="item in arrayAux.history" :key="item.id">
                                <div class="modal-operatoreditaccesstext"> 
                                    <p><span class="modal-operatoreditaccesssubtitle">Nível: </span>{{item.level}}</p>
                                    <p><span class="modal-operatoreditaccesssubtitle">IP Externo: </span> {{item.ipext}} <span class="modal-operatoreditaccesssubtitle">IP Local: </span> {{item.iplocal}}</p>
                                </div>
                                <Map />
                                <div class="modal-operatoreditaccesstext">
                                    <p><span class="modal-operatoreditaccesssubtitle">País: </span>{{item.country}} <span class="modal-operatoreditaccesssubtitle">Cidade:</span> {{item.city}} <span class="modal-operatoreditaccesssubtitle">Região: </span>{{item.region}}</p>
                                    <b-button class="modal-operatoreditaccessbtn">Visualizar Mapa</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="operatorscardpagination">
                            <b-pagination
                            ></b-pagination>
                            <div class=".text-secondary">1 - 10 de 100 contatos cadastrados</div>
                        </div>
                    </b-tab>
                    <b-tab title="Status">
                        <ModalOperatorsHeader :user="arrayAux"/>
                        <div class="modal-operatoreditstatus">
                            <b-row>
                                <b-col>
                                    <b-form-group label="Histórico de Alterações de Status:">
                                            <b-form-input class="modal-operatoreditstatusinput" placeholder="Acompanhe as alterações de status do usuário" disabled/>
                                    </b-form-group>
                                </b-col>
                                <b-col>
                                    <b-form-group label="Selecione uma Data:">
                                        <b-input-group>
                                            <template #append>
                                                <b-button class="datepickersearchbtn"><b-icon class="datepickersearchicon" icon="search"></b-icon></b-button>
                                            </template>
                                            <b-form-datepicker class="modal-operatoreditstatusdatepicker"></b-form-datepicker>
                                        </b-input-group>
                                    </b-form-group>
                                </b-col>
                            </b-row>
                            <div class="modal-operatoreditstatusbody">
                                <div class="modal-operatoreditstatuschange">
                                    <b-row class="togglerow">
                                        <b-col>
                                            <span class="modal-operatoreditstatuschangetext">Status Alterado</span> <span class="toggleontext">para Online</span>
                                        </b-col>
                                        <b-col cols="2">
                                            <b-icon class="toggleicon toggleiconon" icon="toggle-on"></b-icon>
                                        </b-col>
                                        <b-col>
                                            <span class="modal-operatoreditstatuschangetext">Às 00:00</span>
                                        </b-col>
                                    </b-row>
                                    <b-row class="togglerow">
                                        <b-col>
                                            <span class="modal-operatoreditstatuschangetext">Status Alterado</span> <span class="toggleofftext">para Offline</span>
                                        </b-col>
                                        <b-col cols="2">
                                            <b-icon class="toggleicon toggleiconoff" icon="toggle-on" flip-h></b-icon>
                                        </b-col>
                                        <b-col>
                                            <span class="modal-operatoreditstatuschangetext">Às 00:00</span>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="modal-operatoreditstatuschange">

                                </div>
                            </div>
                        </div>
                    </b-tab>
                    <b-tab title="Agenda">
                        <ModalOperatorsHeader :user="arrayAux"/>
                    </b-tab>
                    <b-tab title="Localização">
                        <ModalOperatorsHeader :user="arrayAux"/>
                        <div class="modal-operatoreditlocation">
                            <b-form-group label="Localização:">
                                <b-form-input class="modal-operatoreditstatusinput" placeholder="Este mapa informa as zonas vinculadas ao perfíl sócio-econômico desse cliente." disabled/>
                            </b-form-group>
                            <Map />
                            <b-tabs pills fill class="modal-operatoreditlocationtabs">
                                <b-tab title="Visualizar Endereços" active>
                                    <div class="modal-operatorslocationadress">
                                        <b-row class="modal-operatorslocationadressheader">
                                            <b-col>
                                                CEP
                                            </b-col>
                                            <b-col>
                                                Endereço
                                            </b-col>
                                            <b-col>
                                                Complemento
                                            </b-col>
                                            <b-col>
                                                Bairro
                                            </b-col>
                                            <b-col>
                                                Cidade
                                            </b-col>
                                            <b-col>
                                                Estado
                                            </b-col>
                                            <b-col cols="1"> </b-col>
                                        </b-row>
                                        <b-row class="modal-operatorslocationadressbody">
                                            <b-col>
                                                {{arrayAux.cep}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.street}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.streetnumber}} {{arrayAux.complement}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.district}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.city}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.state}}
                                            </b-col>
                                            <b-col class="operatorslocationiconcol" cols="1">
                                                <div class="operatorslocationicontriangle"></div>
                                                <b-icon class="operatorslocationicon rounded-circle" icon="house-door-fill"></b-icon>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="operatorscardpagination">
                                        <b-pagination
                                        ></b-pagination>
                                        <div class=".text-secondary">1 - 10 de 100 contatos cadastrados</div>
                                    </div>
                                </b-tab>
                                <b-tab title="Telefones Vinculados">
                                    <div class="modal-operatorslocationadress">
                                        <b-row class="modal-operatorslocationadressheader">
                                            <b-col cols="1">
                                                
                                            </b-col>
                                            <b-col>
                                                Telefone
                                            </b-col>
                                            <b-col>
                                                Tipo
                                            </b-col>
                                            <b-col>
                                                Região
                                            </b-col>
                                            <b-col cols="2">
                                                Operadora
                                            </b-col>
                                        </b-row>
                                        <b-row class="modal-operatorslocationadressbody">
                                            <b-col cols="1" style="padding-right:5px;"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp operatorslocationphone" viewBox="0 0 16 16">
                                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                                </svg>
                                                <b-icon class="operatorslocationphoneicon" icon="telephone"></b-icon>
                                            </b-col>
                                            <b-col>
                                                <div class="operatorslocationphone">{{arrayAux.mobile}}</div> 
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.mobiletype}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.state}}
                                            </b-col>
                                            <b-col cols="2">
                                                {{arrayAux.mobilecompany}}
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="operatorscardpagination">
                                        <b-pagination
                                        ></b-pagination>
                                        <div class=".text-secondary">1 - 10 de 100 contatos cadastrados</div>
                                    </div>
                                </b-tab>
                                <b-tab title="Telefones de Empresas Associadas">
                                    <div class="modal-operatorslocationadress">
                                        <b-row class="modal-operatorslocationadressheader">
                                            <b-col cols="1">
                                                
                                            </b-col>
                                            <b-col>
                                                Telefone
                                            </b-col>
                                            <b-col>
                                                Tipo
                                            </b-col>
                                            <b-col>
                                                Região
                                            </b-col>
                                            <b-col cols="2">
                                                Operadora
                                            </b-col>
                                        </b-row>
                                        <b-row class="modal-operatorslocationadressbody">
                                            <b-col cols="1" style="padding-right:5px;"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-whatsapp operatorslocationphone" viewBox="0 0 16 16">
                                                    <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                                                </svg>
                                                <b-icon class="operatorslocationphoneicon" icon="telephone"></b-icon>
                                            </b-col>
                                            <b-col>
                                                <div class="operatorslocationphone">{{arrayAux.mobile}}</div> 
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.mobiletype}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.state}}
                                            </b-col>
                                            <b-col cols="2">
                                                {{arrayAux.mobilecompany}}
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="operatorscardpagination">
                                        <b-pagination
                                        ></b-pagination>
                                        <div>1 - 10 de 100 contatos cadastrados</div>
                                    </div>
                                </b-tab>
                                <b-tab title="Outros Contatos Associados">
                                    <div class="modal-operatorslocationadress">
                                        <b-row class="modal-operatorslocationadressheader">
                                            <b-col>
                                                Nome
                                            </b-col>
                                            <b-col>
                                                Relação
                                            </b-col>
                                            <b-col>
                                                Telefone
                                            </b-col>
                                            <b-col>
                                                Tipo
                                            </b-col>
                                            <b-col>
                                                Região
                                            </b-col>
                                            <b-col>
                                                Operadora
                                            </b-col>
                                        </b-row>
                                        <b-row class="modal-operatorslocationadressbody">
                                            <b-col>
                                                {{arrayAux.name}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.name}}
                                            </b-col>
                                            <b-col>
                                                <div class="operatorslocationphone">{{arrayAux.mobile}}</div>
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.mobiletype}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.state}}
                                            </b-col>
                                            <b-col>
                                                {{arrayAux.mobilecompany}}
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div class="operatorscardpagination">
                                        <b-pagination
                                        ></b-pagination>
                                        <div>1 - 10 de 100 contatos cadastrados</div>
                                    </div>
                                </b-tab>
                            </b-tabs>
                        </div>
                    </b-tab>
                </b-tabs>
            </form>
        </b-modal>
        <b-modal id="modal-deleteOperator" ref="modal-deleteOperator" title="Remover Operador" size="sm" header-class="border-0 p-0 px-3" body-class="p-0">
            <div class="border-bottom border-top text-secondary smaller-text p-3">Tem certeza que deseja remover este operador?</div>
            <div class="p-3">
                <b-button class="modal-btnsearch me-2" style="width: 100px" @click="deleteOperator">Remover</b-button>
                <b-button class="modal-btncancel" style="width: 100px" @click="$bvModal.hide('modal-deleteOperator')">Cancelar</b-button>
            </div>
        </b-modal>
        <b-modal id="transferContacts" ref="transferContacts" title="Transferir Carteira de Clientes" header-class="p-0 px-3" body-class="p-3" hide-footer v-if="arrayAux">
            <div>
                <span class="text-purple fw-semibold">
                    {{ arrayAux.name }}
                </span>
            </div>
            <b-form-group class="mt-2" label="Transferir carteira de clientes para:" label-class="small-text fw-normal" v-if="user.channelConfig?.enableCustomerPortfolio">
                <b-form-select v-model="transferWalletTo" class="rounded w-100 border-grey p-2">
                    <option :value="null">Nenhum</option>
                    <b-form-select-option v-for="op in operators.filter(operator => operator._id != arrayAux._id)" :key="op._id" :value="op._id">{{ op.name }}</b-form-select-option>
                </b-form-select>
            </b-form-group>
            <b-button variant="green" class="text-white" @click="transferWallet()">Transferir</b-button>
        </b-modal>
    </div>
</template>

<script>
import Map from './map.vue'
import ModalOperatorsHeader from './modal-operatorsheader.vue'
import api from '../services/apiService.js'

export default {
    props: [
        'user',
        'socket'
    ],
    components: {
        Map,ModalOperatorsHeader
    },
    created() {
        fetch('https://servicodados.ibge.gov.br/api/v1/localidades/estados/')
        .then(response =>{
            response.json().then(data =>{
                this.states = data;
            })
        }).catch(err => {
            console.log(err);
        })
    },
    mounted: function() {
        this.$nextTick(function() {
            this.onCloseModal()
            this.getOperators()
            this.getDepartments()
            this.checkCrmEnabled()
        })
    },
    data() {
        return{
            arrayAux: { 
                role: 'operator', 
                channelId: this.user.channelId || this.user.roleId,
                portfolioSecondaryAttendance: {
                    action: '',
                    id: ''
                },
                attendanceViewRestrictionType: null,
                restrictOperatorOwnMessagesType: null,
                refresh: 0
             },
            baseUrl: `${process.env.VUE_APP_BASEURL}:5962`, 
            userSelected: null,
            filters: {},
            hasFilter: false,
            channels: [{text: 'Carregando', value: 'loading'}],
            selectoperatoroptions: [
                { value: null, text: 'Selecionar Tudo' },
                { value: 'a', text: 'Deslogar Selecionado' },
                { value: 'b', text: 'Desativar Selecionado' },
                { value: 'c', text: 'Ativar Selecionado' },
                { value: 'd', text: 'Remover Selecionado'}
            ],
            selectfilteroptions: [
                { value: null, text: 'Selecione o filtro' },
                { value: 'keyword', text: 'Palavra-Chave' },
                { value: 'status', text: 'Status' },
                { value: 'department', text: 'Departamento' },
            ],
            selectgenderoptions: [
                { value: 'M', text: 'Masculino'},
                { value: 'F', text: 'Feminino'},
                { value: null, text: 'Outro'},
            ],
            selectstateoptions: [
                { value: '', text: 'Santa Catarina'},
            ],
            selectnewansermsgoptions: [
                { value: null, text: 'Selecionar Tudo' },
                { value: 'a', text: 'Ativar Resposta' },
                { value: 'b', text: 'Remover Resposta'}
            ],
            states:[],
            operators: [],
            departments: [],
            message: [
                {
                    title: 'Informações',
                    content: 'Mensagem test',
                    date: '25/05/2021',
                },
                {
                    title: 'Sobre a GOBOT',
                    content: 'Mensagem test',
                    date: '25/05/2021 as 00:00',
                },
            ],
            transferWalletTo: null,
            crmEnabled: false,
        }
    },
    methods: {
        async getOperators() {
            let resp = await api.getOperators(this.user.channelId || this.user.roleId)
            // console.log({resp})
            if(resp.statusCode!=200) {
                this.operators = []
                this.operators['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.operators = resp.operators.filter(el => this.user.departments?.some(e => el.department.includes(e)))

            this.operators = resp.operators.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
            // console.log('operators',this.operators)
        },
        async getDepartments() {
            const resp = await api.getDepartments(this.user.channelId || this.user.roleId)
            if (resp.statusCode != 200) {
                this.departments = []
                this.departments['total'] = 0
                return
            }

            if(this.user.role == "channel_manager" && this.user.departments?.at(0) !== 'all')
                resp.departments = resp.departments.filter(el => this.user.departments?.includes(el._id))

            this.departments = resp.departments
            // console.log('departments',this.departments)
        },
        async selectUser(item) {
            let resp = await api.getOperator(item._id)
            if(!resp.operator && resp.statusCode != 200) {
                this.arrayAux = Object.assign({}, item)
                if (!this.arrayAux.attendanceViewRestrictionType)
                    this.arrayAux.attendanceViewRestrictionType = null
                if (!this.arrayAux.restrictOperatorOwnMessagesType)
                    this.arrayAux.restrictOperatorOwnMessagesType = null
                this.userSelected = item
            } else {
                resp.operator.login = resp.user.login
                this.arrayAux = Object.assign({}, resp.operator)
                if (!this.arrayAux.attendanceViewRestrictionType)
                    this.arrayAux.attendanceViewRestrictionType = null
                if (!this.arrayAux.restrictOperatorOwnMessagesType)
                    this.arrayAux.restrictOperatorOwnMessagesType = null
                this.userSelected = resp.operator
            }
        },
        async deleteOperator() {
            console.log(this.arrayAux)
            let resp = await api.deleteOperator(this.arrayAux._id)
            let msg = ''
            if(resp.error || resp.statusCode != 200) {
                msg = {
                    text:'Ocorreu um erro!',
                    success: false
                }
            } else {
                msg = {
                    text: "Operador removido com sucesso!",
                    success: true
                }
            }
            this.$refs['modal-deleteOperator'].hide()
            this.$emit('msg',msg)
            this.getOperators()
        },
        async saveUser() {
            this.arrayAux.role = 'operator'
            let isNew = false
            let resp = null
            let msg = ''
            if(!this.arrayAux.department?.length) {
                let msg = {
                    text: "Nenhum Departamento Selecionado!",
                    sucess: false
                }
                return this.$emit('msg',msg)
            }
            if(!this.arrayAux._id) {
                isNew = true
            }
            if(this.arrayAux.phone) {
                this.arrayAux.phone = this.arrayAux.phone.replace(/\D+/g, '')
            }

            if(!this.arrayAux.disableAudio)
                this.arrayAux.disableAudio = false
            if(!this.arrayAux.disableMedia)
                this.arrayAux.disableMedia = false
            if(!this.arrayAux.disableEditName)
                this.arrayAux.disableEditName = false
            if(!this.arrayAux.disableActiveCommunication)
                this.arrayAux.disableActiveCommunication = false
            if(!this.arrayAux.viewOperatorContactsOnly)
                this.arrayAux.viewOperatorContactsOnly = false
            if(!this.arrayAux.restrictOperatorOwnMessages)
                this.arrayAux.restrictOperatorOwnMessages = false
            if(!this.arrayAux.restrictOperatorOwnMessagesHideChatbot)
                this.arrayAux.restrictOperatorOwnMessagesHideChatbot = false
            if(!this.arrayAux.breakTime?.length)
                this.arrayAux.breakTime = [null]

            if(this.arrayAux.restrictOperatorOwnMessages && this.arrayAux.attendanceViewRestriction) {
                this.arrayAux.restrictOperatorOwnMessages = false
            }

            if(isNew) {
                this.arrayAux.channelId = this.user.channelId || this.user.roleId
                resp = await api.createUser(this.arrayAux)
            } else {
                if((this.arrayAux.login != this.userSelected.login) || this.arrayAux.password) {
                    const userEdit = await api.updateUser(this.arrayAux)
                    console.log('userEdit',userEdit)
                    if(userEdit.statusCode == 409) {
                        msg = {
                            text: `O login digitado já existe!`,
                            success: false
                        }
                        return this.$emit('msg',msg)
                    }
                }
                resp = await api.updateOperator(this.arrayAux)
            }
            if(resp.statusCode == 200 || resp.statusCode == 201) {
                msg = {
                    text: `Operador ${isNew ? 'criado' : 'editado'} com sucesso`,
                    success: true
                }
                this.arrayAux = {
                    role: 'operator', 
                    channelId: this.user.channelId || this.user.roleId,
                    portfolioSecondaryAttendance: {
                        action: '',
                        id: ''
                    }
                }
                this.$refs['modalEditOperator'].hide()
            } else if(resp.statusCode == 406) {
                msg = {
                    text: `O limite de operadores foi atingido!`,
                    success: false
                }
            } else if(resp.statusCode == 409) {
                msg = {
                    text: `O login digitado já existe!`,
                    success: false
                }
            } else { 
                msg = {
                    text:'Ocorreu um erro, revise os dados e tente novamente!',
                    success: false
                }
            }
            this.$emit('msg',msg)
            this.getOperators()
        },
        depName(_id) { // returns the name of the given _id department
            if(this.departments.length) {
                let dep = this.departments.find(el=>el._id == _id)
                if(dep)
                    return dep.name
            }
        },
        scheduleDays(array) {
            if(this.arrayAux.weekDays)
                return this.arrayAux.weekDays.some(r=> array.includes(parseInt(r)))
            else
                return false
        },
        async verifyOperatorsQuantity() {
            let channelConfigResp = await api.getChannelConfig(this.user.channelId || this.user.roleId)
            if(channelConfigResp.statusCode == 200) {
                let channelConfig = channelConfigResp.channelConfig
                if(this.operators.length >= channelConfig.contractedPlan?.numberOperators) {
                    this.$refs['modalEditOperator'].hide()
                    let msg = {
                        // text: `Número máximo de operadores excedido (${channelConfig.contractedPlan.numberOperators})`,
                        text: `O limite de operadores foi atingido!`,
                        success: false
                    }
                    this.$emit('msg',msg)
                }
            }
        },
        onCloseModal() {
            this.$root.$on('bv::modal::hide', () => {
                this.userSelected = null
                this.arrayAux = {
                    role: 'operator', 
                    channelId: this.user.channelId || this.user.roleId,
                    portfolioSecondaryAttendance: {
                        action: '',
                        id: ''
                    },
                    attendanceViewRestrictionType: null,
                    restrictOperatorOwnMessagesType: null
                }
                this.transferWalletTo = null
            })
        },
        async filterOperators() {
            await this.getOperators()
            if(this.filters?.type) {
                if(this.filters.type == 'keyword' && this.filters.keyword) {
                    this.operators = this.operators.filter(el => (el.name.toLowerCase().includes(this.filters.keyword.toLowerCase())) )
                    this.hasFilter = true
                    if(this.$refs["modal-operatorsfilter"])
                        this.$refs["modal-operatorsfilter"].hide()
                } else if(this.filters.type == 'status') {
                    this.operators = this.operators.filter(el => (el.status == this.filters.status))
                    this.hasFilter = true
                    if(this.$refs["modal-operatorsfilter"])
                        this.$refs["modal-operatorsfilter"].hide()
                } else if(this.filters.type == 'department' && this.filters.department) {
                    this.operators = this.operators.filter(el => (el.department.includes(this.filters.department)))
                    this.hasFilter = true
                    if(this.$refs["modal-operatorsfilter"])
                        this.$refs["modal-operatorsfilter"].hide()
                }
            }
        },
        operatorLogout(operator) {
            if (!this.socket.active) {
                return this.$emit("msg", {
                    text: "Ocorreu um erro! Por favor, recarregue a página",
                    success: false
                })
            }

            if(operator._id) {
                operator.status = operator.connected
                this.socket.emit("adm_operator_logout", operator._id)
            }
        },
        async transferWallet() {
            let msg = null
            if(!this.userSelected) {
                msg = {
                    text: "Ocorreu um erro!",
                    success: false
                }
                return this.$emit("msg",msg)
            }
            let resp = await api.transferWallet(this.userSelected._id, this.transferWalletTo)
            // console.log({resp})
            if(resp.statusCode == 200) {
                msg = {
                    text: `${resp.count} contatos transferidos!`,
                    success: true
                }
                this.$bvModal.hide("transferContacts")
            } else {
                msg = {
                    text: "Ocorreu um erro!",
                    success: false
                }
            }
            if(msg)
                this.$emit("msg",msg)
        },
        addBreakTime() {
            if (!this.arrayAux.breakTime)
                this.arrayAux.breakTime = []

            this.arrayAux.breakTime.push({
                start: "",
                finish: ""
            })
        },
        removeBreakTime(breakTimeIndex) {
            if (breakTimeIndex !== undefined) {
                this.arrayAux.breakTime.splice(breakTimeIndex, 1)
            }
        },
        changedAttendanceViewRestriction(e) {
            if (e) {            
                this.$set(this.arrayAux, "attendanceViewRestrictionType", this.arrayAux.restrictOperatorOwnMessagesType)
            } else {
                this.$set(this.arrayAux, "restrictOperatorOwnMessagesType", this.arrayAux.attendanceViewRestrictionType)
            }
        },
        changedRestrictOperatorOwnMessages(e) {
            if (!e) {
                this.arrayAux.restrictOperatorOwnMessages = false
                this.arrayAux.attendanceViewRestriction = false
            } else {
                if (!this.arrayAux.attendanceViewRestriction)
                    this.arrayAux.restrictOperatorOwnMessages = true
            }
        },
        async checkCrmEnabled() {
            const channelConfig = await api.getChannelConfig(this.user.channelId)

            if (channelConfig.modules.crm && channelConfig.modules.futureConnect) {
                return true
            }

            return false
        }
    }
}
</script>

<style>
    .operatorscardshboxes{
        display: flex;
        align-items: center;
    }
    .operatorscardshboxes select{
        transform: scale(0.75);
        background-color: hsl(339deg 79% 68%);
        color: #fff;
        max-width: 25px;
        border-radius: 3px;
        border: 1px solid hsl(339deg 79% 68%);
        padding: 0 10px;
    }
    .operatorscardshboxes label{
        display: flex;
        align-self: center;
    }
    .operatorscardshboxes select option{
        background-color: #fff;
        color: #888;
    }
    .page-operatorsmanage .form-switch input[type=checkbox]{
        height: 0;
        width: 0;
        visibility: hidden;
    }
    .page-operatorsmanage .form-switch .custom-checkbox{
        display: flex;
    }
    .page-operatorsmanage .form-switch label {
        text-indent: -9999px;
        width: 3em;
        height: 1.33em;
        background: #ddd;
        display: block;
        border-radius: 100px;
        position: relative;
    }
    .page-operatorsmanage .form-switch label:after {
        content: '';
        position: absolute;
        top: 2.2px;
        left: 3px;
        width: 1em;
        height: 1em;
        background: #dc3545;
        border-radius: 28px;
        transition: 0.3s;
    }
    .page-operatorsmanage .form-switch input:checked + label:after {
        left: calc(100% - 2px);
        transform: translateX(-100%);
        background-color: hsl(93deg 44% 65%);
    }
    .page-operatorsmanage .form-switch input:checked + label {
        cursor: pointer;
    }
    #modal-operatoredit .close, #modal-operatorsnewanswer .close, #modal-operatorsfilter .close, #modalEditOperator .close, #modal-deleteOperator .close{
        border: none;
        background-color: transparent;
        font-size: 2em;
        color: #555;
    }
    #modal-operatoredit .modal-title, #modal-operatorsnewanswer .modal-title, #modal-operatorsfilter .modal-title, #modalEditOperator .modal-title, #modal-deleteOperator .modal-title{
        color: #555;
    }
    #modal-operatoredit .modal-footer, #modal-operatorsnewanswer .modal-footer, #modal-operatorsfilter .modal-footer, #modalEditOperator .modal-footer, #modal-deleteOperator .modal-footer{
        display: none;
    }
    #modal-operatoredit .col-form-label, #modal-operatorsnewanswer .col-form-label, #modal-operatorsfilter .col-form-label, #modalEditOperator .col-form-label {
        color: #777;
        font-weight: 500;
    }
    .modal-content{
        border: none;
    }
    .modal-operatoredittabtop .nav-tabs .nav-link{
        color:#333;
    }
    .modal-operatoredittabtop .nav-tabs .nav-link.active{
        color:#333;
        font-weight: 500;
    }
    .modal .operatorscardpagination{
        padding: 0px !important;
    }
    .modal-operatoreditlocationtabs .nav-link{
        color: #555;
        padding: 0.5rem 0.1rem;
        font-size: 0.9em;
        font-weight: 500;
    }
    .modal-operatoreditlocationtabs .nav-pills .nav-link.active, .modal-operatoreditlocationtabs .nav-pills .show > .nav-link{
        background-color: hsl(232deg 37% 62%);
    }
    #modal-operatorsnewanswer .operatorscardpagination{
        border-bottom: .5px solid #eee;
        margin-bottom: 20px;
    }
    .inputtagsstate output{
        display: none;
    }
    .inputtagsstate .b-form-tag{
        background-color: hsl(94deg 51% 73%);
        border-radius: 5px;
        font-size: 1em;
        align-items: center !important;
    }
</style>

<style scoped>
    .page-operatorsmanage>.card:not(:first-child) {
        margin-top: 20px;
    }
    .card{
        border: none;
    }
    .cardtop{
        display: flex;
        align-items: center;
        text-align: center;
        padding: 20px 0;
    }
    .cardtop:first-child{
        margin-right: 10px;
    }
    .cardtop:last-child{
        margin-left: 10px;
    }
    .cardnewoperator{
        background-color: hsl(94deg 44% 65%);
        color: hsl(94deg 44% 65%);
    }
    .cardanswers{
        background-color: hsl(338deg 68% 72%);
        color: hsl(338deg 68% 72%);
    }
    .cardtopavatar{
        border: 8px solid #fff;
        border-radius: 50%;
        height: 100px;
        width: 100px;
    }
    .cardtoptitle{
        color: #fff;
        margin: 15px 0;
    }
    .cardtopbtn{
        background-color: #fff;
        border-color: #fff;
        color: inherit;
        padding: 5px 25px;
    }
    .cardtopbtn:hover{
        background-color: inherit;
        color: #fff;
    }
    .cardanswersicon{
        background-color: #fff;
        border-radius: 50%;
        height: 100px;
        width: 100px;
        padding: 20px;
    }
    .operatorscard .card-body{
        padding: 0;
    }
    .operatorscardheader{
        border-bottom: 1px solid #eee;
        padding: 30px;
        display: flex;
        justify-content: space-between;
        align-content: center;
    }
    .operatorscardtitle{
        color: #333;
        display: flex;
        align-self: center;
    }
    .operatorscardheaderright{
        display: flex;
        align-items: center;
    }
    .operatorscardtexttotal{
        color: #888;
    }
    .operatorscardnumbertotal{
        margin: 0 20px;
        background-color: hsl(232deg 37% 62%);
        color: #fff;
        display:inline-block;
        font-size:20px;
        font-weight: 500;
    }
    .operatorscardnumbertotal:before,
    .operatorscardnumbertotal:after {
        content:'\200B';
        display:inline-block;
        line-height:0px;

        padding-top:50%;
        padding-bottom:50%;
    }
    .operatorscardnumbertotal:before {
        padding-left:8px;
    }
    .operatorscardnumbertotal:after {
        padding-right:8px;
    }
    .operatorscardheaderbtn{
        background-color: hsl(94deg 44% 65%);
        border: none;
        padding: 5px 25px;
    }
    .operatorscardheaderbtn:hover{
        background-color: hsl(94deg 34% 60%);
    }
    .operatorscardsubheader{
        padding: 15px 30px;
        border-bottom: 1px solid #eee;
    }
    .operatorscardshtitle{
        float: left;
        font-size: 0.9em;
        font-weight: 700;
        color: #777;
    }
    .operatorscardbodyrow{
        padding: 10px 30px;
        border-bottom: 1px solid #eee;
    }
    .operatorscardstatuscol{
        justify-content:left !important;
    }
    .operatorscardcol{
        display: flex;
        justify-content: space-between;
        align-items: center;
        word-break: break-all;
    }
    .operatorscardnamecb{
        margin-right: 10px;
    }
    .operatorscardname{
        color: hsl(232deg 37% 62%);
        font-weight: 500;
    }
    .operatorscarddescription{
        color: hsl(232deg 39% 65%);
    }
    .operatorscardcontacts{
        color: hsl(232deg 39% 65%);
    }
    .operatorscardoptionsbtn{
        border: none;
        border-radius: 2px;
    }
    .operatorscardoptionsbtn:last-child{
        margin-right: 0px;
    }
    .operatorscardoptionsbtn:hover {
        background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1));
    }
    .operatorsavatar{
        height: 50px;
        width: 50px;
        border-radius: 50%;
    }
    .channeldisabletext{
        margin-bottom: 10px;
        font-size: 0.8em;
        font-weight: 500;
        color: #777;
        border-top: 0.5px solid #eee;
        border-bottom: 0.5px solid #eee;
        padding: 10px 0;
    }
    .modal-select{
        color: #555;
        border-color: #eee;
        padding: 0 10px;
    }
    .modal .form-group{
        margin-bottom: 20px;
    }
    .modal-operatoredittitle{
        color: #555;
    }
    .modal-operatoreditaccesshistory{
        border: 0.5px solid #eee;
        padding: 10px;
    }
    .modal-operatoreditaccesshistory:not(:first-child) {
        margin-top: 10px;
    }
    .modal-operatoreditaccesshistory p{
        margin: 0;
        color: #555;
    }
    .modal-operatoreditaccesstext{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .modal-operatoreditaccesssubtitle{
        color: #555;
        font-weight: 500;
    }
    .modal-operatoreditaccesssubtitle:not(:first-child) {
        margin-left: 20px;
    }
    .tab-pane>div:not(:first-child) {
        margin-top: 20px;
    }
    .modal-operatoreditaccessbtn{
        background-color: hsl(112deg 37% 72%);
        border-color: hsl(112deg 37% 72%);
        color: #fff;
    }
    .modal-operatoreditaccessbtn:hover{
        background-color: hsl(112deg 30% 62%);
        border-color: hsl(112deg 30% 62%);
    }
    .modal-operatoreditprofile select{
        border-color: #ccc;
        color: #212529;
        width: 100%;
        padding: 6px 10px;
    }
    .modal-operatoreditstatusinput{
        background-color: hsl(112deg 37% 72%);
        border-color: hsl(112deg 37% 72%);
        color: #fff;
    }
    .modal-operatoreditstatusinput::placeholder{
        color: #fff;
    }
    .modal-operatoreditstatusbody{
        background-color: hsl(0deg 0% 90%);
        padding: 20px 30px;
        overflow-y: auto;
    }
    .modal-operatoreditstatuschange{
        display: flex;
        flex-direction: column;
    }
    .modal-operatoreditstatuschange .col:first-child{
        text-align: right;
    }
    .modal-operatoreditstatuschange .col{
        background-color: #fff;
        align-self: center;
        padding: 10px 30px;
        border-radius: 2px;
    }
    .modal-operatoreditstatuschange .col-2{
        text-align: center;
        align-self: center;
    }
    .toggleicon{
        color: #fff;
        border-radius: 50%;
        padding: 10px;
        font-size: 2.75em;
    }
    .toggleiconon{
        background-color: hsl(113deg 38% 72%);
    }
    .toggleiconoff{
        background-color: hsl(360deg 88% 70%);
    }
    .modal-operatoreditstatuschangetext{
        font-weight: 600;
        color: #333;
    }
    .toggleontext{
        color: hsl(113deg 30% 62%);
    }
    .toggleofftext{
        color: hsl(360deg 80% 60%);
    }
    .togglerow:not(:first-child) {
        margin-top: 20px;
    }
    .modal-operatoreditlocationtabs{
        margin-top: 20px;
    }
    .modal-operatorslocationadress{
        margin-top: 20px;
        border: 0.5px solid #eee;
        padding: 10px;
    }
    .modal-operatorslocationadressheader{
        border-bottom: 0.5px solid #eee;
        font-size: 0.9em;
        font-weight: 500;
        color: #777;
        padding-bottom: 10px;
    }
    .modal-operatorslocationadressbody{
        font-size: 0.9em;
        color: hsl(0deg 0% 69%);
        padding: 10px 0;
        font-weight: 500;
    }
    .operatorslocationiconcol{
        position: relative;
    }
    .operatorslocationicon{
        background-color: hsl(203deg 96% 22%);
        color: #fff;
        font-size: 1.75em;
        padding: 5px;
        cursor: pointer;
        z-index: 1;
        position: relative;
    }
    .operatorslocationicontriangle{
        background-color: hsl(203deg 96% 22%);
        position: absolute;
        height: 10px;
        width: 10px;
        right: 53.79%;
        top: 18px;
        transform: rotate(45deg);
        z-index: 0;
        cursor: pointer;
    }
    .operatorslocationphoneicon{
        color: hsl(229deg 38% 65%);
        font-size: 1.25em;
    }
    .operatorslocationphoneicon:last-child{
        float: right;
    }
    .operatorslocationphone{
        color: hsl(229deg 38% 65%);
    }
    .modal-operatorsnewanswerselect{
        width: 100%;
    }
    .modal-operatorsnewanswerselect, .modal-operatorsnewanswerinput, .modal-operatorsnewanswertextarea{
        color: #555;
        border-color: #eee;
        padding: 5px 10px;
    }
    .modal-operatorsnewanswerinput::placeholder{
        color: #555;
    }
    .operatorsnewanswershtitle{
        font-weight: 500;
        color: hsl(250deg 10% 60%);
    }
    .newanswermsgrow{
        padding: 20px 0;
    }
    .newanswermsgcol{
        display: flex;
        place-items: flex-start;
        justify-content: space-between;
    }
    .newanswermsg{
        border: 0.5px solid #eee;
        padding: 10px;
        margin-left: 10px;
        font-size: 0.9em;
        word-break: break-all;
        width: 100%;
    }
    .newanswermsgtitle{
        font-weight: 500;
        color: #777;
        margin-bottom: 5px;
    }
    .newanswermsgcontent{
        color: #999;
    }
    .newanswermsgdate{
        border: 0.5px solid #eee;
        word-break: break-all;
        color: hsl(250deg 14% 64%);
        padding: 10px;
        font-size: 0.9em;
        /* white-space: nowrap; */
    }
    .newanswermsgcol .operatorscardoptionsbtn{
        font-size: 1.15em;
        padding: 8px;
        margin: 0;
    }
    .newanswermsgcol .operatorscardoptionsbtn:first-child{
        margin-right: 5px;
    }
    .operatorsnewanswersubheader{
        padding: 15px 0px;
        border-bottom: 1px solid #eee;
    }
    .datepickersearchbtn{
        background-color: hsl(112deg 37% 72%);
        border-color: hsl(112deg 37% 72%);
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
    }
    .datepickersearchbtn:hover{
        background-color: hsl(112deg 30% 62%);
        border-color: hsl(112deg 30% 62%);
    }
    @-moz-document url-prefix() {
        .groupscardshboxes select{
            transform: scale(0.75);
            /* margin-bottom: 1px; */
            padding-top: 1px;
        }
    }
    .label *
    {
        cursor: pointer;
    }
    input[type="checkbox"]
    {
        opacity: 0;
        position: absolute;
    }
    input[type="checkbox"] + span
    {
        color: #888;
        font-size: 0.9em;
        display: flex;
    }
    label:hover span::before
    {
        -moz-box-shadow: 0 0 2px #ccc;
        -webkit-box-shadow: 0 0 2px #ccc;
        box-shadow: 0 0 2px #ccc;
    }
    input[type="checkbox"] + span::before
    {
        content: "";
        width: 18px;
        height: 18px;
        margin: 0 4px 0 0;
        border: solid 1px #ccc;
        line-height: 14px;
        text-align: center;
        -moz-border-radius: 2px;
        -webkit-border-radius: 2px;
        border-radius: 2px;
    }
    input[type="checkbox"]:checked + span::before
    {
        color: #666;
        content: "\2713";
        font-size: 12px;
        font-weight: 900;
    }
    input[type="checkbox"]:disabled + span
    {
        cursor: default;
        -moz-opacity: .4;
        -webkit-opacity: .4;
        opacity: .4;
    }
    @media (max-width:425px) {
        .cardtop:first-child,.cardtop:last-child{
            margin-left:0;
            margin-right:0;
        }
        .operatorscardheader{
            padding: 1em;
            display: block;
        }
        .operatorscardtitle,.operatorscardheaderright{
            justify-content: center;
        }
        .operatorscardnumbertotal{
            padding: 0.1em 0.25em;
        }
        .operatorscardnumbertotal:before, .operatorscardnumbertotal:after{
            content: none;
        }
    }
</style>